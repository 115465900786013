.title-position-left {
  @apply justify-start
}

.title-position-center {
  @apply justify-center
}

.title-position-right {
  @apply justify-end
}

.title-font-size-lg {
  @apply text-lg
}

.title-font-size-xl {
  @apply text-xl
}

.title-font-size-2xl {
  @apply text-2xl
}

.title-font-bold-semibold {
  @apply font-semibold
}

.title-font-bold-bold {
  @apply font-bold
}

.title-font-bold-extrabold {
  @apply font-extrabold
}

.title-font-bold-black {
  @apply font-black
}

.title-font-color-lipa {
  @apply text-tamagotchi
}

.title-font-color-tamagotchi {
  @apply text-tamagotchi
}

.title-font-color-black {
  @apply text-black
}

.title-font-color-white {
  @apply text-white
}

