.select {
  @apply block py-2 px-4 rounded-md transition ease-in-out border-2 border-transparent bg-white hover:border-b-black focus:outline-none focus:border-b-black cursor-pointer
}

.select-base {
  @apply w-full border rounded-md py-2 px-4 pr-8 bg-white
}

.select-success {
  @apply border-b-success bg-emerald-50 hover:border-b-success focus:border-b-success
}

.select-error {
  @apply border-b-error bg-red-50 hover:border-b-error focus:border-b-error
}

.select-warning {
  @apply border-b-warning bg-red-50 hover:border-b-warning focus:border-b-warning
}
