.service-card-icon-sm {
  @apply w-3 md:w-4
}

.service-card-icon-md {
  @apply w-5 md:w-6
}

.service-card-icon-lg {
  @apply w-7 md:w-8
}

.service-card-circle-sm {
  @apply w-9 h-9 md:w-12 md:h-12
}

.service-card-circle-md {
  @apply w-11 h-11 md:w-14 md:h-14
}

.service-card-circle-lg {
  @apply w-12 h-12 md:w-16 md:h-16
}

.service-card-icon-posicion-left {
  @apply items-center flex-row
}

.service-card-icon-posicion-center {
  @apply items-center flex-col
}

.service-card-circle-lipa {
  @apply bg-[#ffecff]
}

.service-card-circle-tamagotchi {
  @apply bg-[#efefff]
}