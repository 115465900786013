/* TailwindCSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/** Components Styles */
@import './components/Alert.css';
@import './components/Button.css';
@import './components/IconButton.css';
@import './components/OptionCard.css';
@import './components/ServiceCard.css';
@import './components/TextField.css';
@import './components/HelperText.css';
@import './components/Label.css';
@import './components/Title.css';
@import './components/Select.css';

body {
  @apply bg-tamagotchi h-screen;
}

.card {
  @apply p-3 pt-5 rounded-xl bg-white;
}

.shadow-card {
  --tw-shadow: -5px 5px 0px 0px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: -5px 5px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c1c1c1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clipped {
  clip: rect(0 0 0 0);
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  display: block;
  margin: 0 -1px -1px 0;
  overflow: hidden;
  width: 0;
  padding: 0;
  position: absolute;
  z-index: -1;
}

/** React swiper **/
.swiper {
  position: static !important;
}

div.swiper-button-prev,
div.swiper-button-next {
  transition: all 0.3s ease-in-out;
  animation-duration: 200ms;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(238, 238, 238, 0.8);
}

div.swiper-button-prev:hover,
div.swiper-button-next:hover {
  box-shadow: 0px 3px 3px #00000029;
  background-color: rgba(238, 238, 238);
}

.swiper-button-next,
.swiper-button-prev {
  right: 10px;
  color: rgb(58, 58, 58) !important;
  fill: rgb(58, 58, 58) !important;
  stroke: rgb(58, 58, 58) !important;
  margin-right: -1rem;
  margin-left: -1rem;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.3rem !important;
  font-weight: 900;
  width: 11px;
  height: 18px;
  opacity: 1;
}

.swiper-pagination-bullet {
  opacity: 0.3;
  background-color: #4b4b4b;
  width: 14px !important;
  height: 14px !important;
}

.swiper-pagination-bullet-active {
  opacity: 0.8;
  background-color: #6064ff !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #6064ff !important;
}

@media screen and (min-width: 768px) {
  div.swiper-button-prev,
  div.swiper-button-next {
    background-color: rgba(238, 238, 238, 0.5);
  }

  .swiper-button-prev {
    margin-left: -2.2rem !important;
  }

  .swiper-button-next {
    margin-right: -2.2rem !important;
  }
}

div.swiper-pagination.swiper-pagination-slider.swiper-pagination-clickable.swiper-pagination-bullets-dynamic.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 110px;
}

.gray-shadow-button {
  --tw-shadow: -2px 2px 0px 0px rgb(217, 217, 217);
  --tw-shadow-colored: -2px 2px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
