.helper-text {
  @apply text-xs leading-tight text-gray-600
}

.helper-text-lipa {
  @apply text-lipa
}

.helper-text-tamagotchi {
  @apply text-tamagotchi
}

.helper-text-info {
  @apply text-info
}

.helper-text-error {
  @apply text-error
}

.helper-text-success {
  @apply text-success
}

.helper-text-warning {
  @apply text-warning
}
