.option-card-icon-sm {
  @apply w-3
}

.option-card-icon-md {
  @apply w-5
}

.option-card-icon-lg {
  @apply w-7
}

.option-card-circle-sm {
  @apply w-8 h-8
}

.option-card-circle-md {
  @apply w-10 h-10
}

.option-card-circle-lg {
  @apply w-12 h-12
}

.option-card-icon-posicion-left {
  @apply items-start
}

.option-card-icon-posicion-center {
  @apply items-center
}

.option-card-circle-lipa {
  @apply bg-[#ffecff]
}

.option-card-circle-tamagotchi {
  @apply bg-[#efefff]
}