.label {
  @apply font-medium tracking-wider
}

.label-lipa {
  @apply text-lipa
}

.label-tamagotchi {
  @apply text-tamagotchi
}

.label-white {
  @apply text-white
}

.label-success {
  @apply text-success
}

.label-error {
  @apply text-error
}

.label-warning {
  @apply text-warning
}

.label-info {
  @apply text-info
}